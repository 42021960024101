.menu_wrapper {
  height: 100%;
  background-color: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.menu_wrapper.dark {
  background-color: #1e1e1e;
  color: white;
}

@media only screen and (max-height: 800px) {
  .menu_wrapper {
    overflow: scroll;

    display: block;
  }
}
