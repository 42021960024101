div.data_wrapper {
  font-size: 1.4rem;

  display: flex;
  flex-direction: column;
}

div.data_wrapper h2 {
  font-size: 1.6rem;
  margin-top: 1rem;
}
