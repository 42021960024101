.function_wrapper {
  margin-right: 1rem;
  margin-top: 1rem;
}

.function_description {
  user-select: none;
  white-space: pre-wrap;
}

.function_composition {
  font-family: Consolas, 'Courier New', monospace;
  background-color: rgb(236, 236, 236);
  color: black;
  margin: 2rem 0;
  padding: 0.5rem;
  user-select: none;

  display: flex;
  align-items: baseline;
}

.function_composition .return_value {
  font-size: 1.2rem;
  color: #6e6e6e;
  font-weight: 500;
  margin-right: 0.5rem;
}

.function_composition .name {
  font-weight: 600;
  margin-right: 0.1rem;
}

.parameter_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.function_composition .parenthesis {
  font-weight: 600;
  margin-right: 0.1rem;
}

.function_composition .parameter {
  display: flex;
  align-items: center;
}

.function_composition .parameter .type {
  font-size: 1.2rem;
  color: #6e6e6e;
  font-weight: 500;
  margin-right: 0.5rem;
}

.function_composition .parameter .name {
  font-weight: 600;
  margin-right: 0.1rem;
}

.function_composition .parameter .colon {
  font-weight: 500;
  margin-right: 0.5rem;
}

.function_example_wrapper {
  margin: 2rem 0;
}

.function_example_title {
  margin-top: 4rem;
  font-weight: 600;
}

.function_example {
  margin: 2rem 0;
}

.function_example p {
  padding: 0.5rem;
  background-color: rgb(236, 236, 236);
  font-weight: 600;
  color: black;
  white-space: pre-wrap;
  font-family: Consolas, 'Courier New', monospace;
  tab-size: 4;
}

.function_example button.copy_example {
  text-decoration: none;
  background-color: #3e3e3e;
  color: white;
  cursor: pointer;
  border: none;
  padding: 0.5rem 1rem;
  outline: none;
}

.function_example button.copy_example:hover {
  background-color: #62b22e;
}
.function_example button.copy_example:active {
  position: relative;
  top: 1px;
}
