button.owm-btn {
  white-space: nowrap;
  cursor: pointer;
  border: none;
  outline: none;
  touch-action: manipulation;
  text-align: center;
  min-width: 128px;
  border-radius: 0.5pt;
  vertical-align: middle;
  padding: 12px 18px;
}
@media only screen and (max-width: 1270px) {
  button.owm-btn {
    font-size: 75%;
  }
}

button.owm-btn:active {
  position: relative;
  top: 1px;
}

button.owm-btn-neutral {
  background-color: #e1e1e1;
}

button.owm-btn-neutral:hover {
  background-color: #c8c8c8;
}

button.owm-btn-success {
  background-color: #79b51c;
  color: white;
}

button.owm-btn-success:hover {
  background-color: #5b8915;
}

button.owm-btn-warning {
  background-color: #eb6909;
  color: white;
}

button.owm-btn-error {
  background-color: crimson;
  color: white;
}

button.owm-btn[disabled] {
  cursor: not-allowed;
  filter: grayscale(50%);
}
