div.output.hidden {
  display: none;
}

div.output_panel {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  font-family: 'Droid Sans Mono', monospace, monospace, 'Droid Sans Fallback';
  background: #2e2e2e;
  color: #d4d4d4;
  border-left: solid 1px black;
  border-right: solid 1px black;
}

div.output_panel pre {
  margin: 0.4rem 0;
}

div.output_panel .output_panel_data {
  overflow-x: auto;
  padding: 1.5rem;
  font-size: 1.4rem;
}

span.output_panel_timestamp {
  color: rgb(122, 122, 122);
}

.output_panel_message {
  display: flex;
}
