.search_bar_wrapper {
  padding: 0.5rem;
  margin: 1rem 0.5rem 2rem 0.5rem;
  border-radius: 3rem;
  background-color: #f1f1f1;

  display: flex;
}

.search_bar_wrapper input {
  width: 100%;
  border: none;
  font-weight: 500;
  padding: 0.5rem 1rem;
  background-color: transparent;
  flex: 1;
  outline: none;
}

.search_bar_wrapper .search_icon {
  width: 2rem;
  height: 2rem;
  margin: 0.5rem 1rem;
  background-image: url(../../../assets/icons/icon_search.svg);
  background-repeat: no-repeat;
}
