.editor-wrapper {
  flex: 2 0;
  overflow: hidden;
}

#monaco-container {
  height: 100%;
}

div.lines-content {
  padding-left: 2rem;
}
