*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  /* This defines what 1rem is */
  font-size: 62.5%; /* 1 rem = 10px; 10px/16px = 62.5% */
  -webkit-text-size-adjust: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 1023px) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (max-width: 839px) {
  html {
    font-size: 50%;
  }

  #template_popup_wrapper {
    transform: translate(-50%, -50%);
    min-width: 0;
    width: 90%;
    margin: 0;
  }
}

@media only screen and (max-width: 663px) {
  html {
    font-size: 43.75%;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  letter-spacing: 0.25px;
}

#root {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  font-size: 1.4rem;
}

div.logo-container {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  position: fixed;
}

div.logo {
  width: 60vw;
  height: 100vh;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  align-items: center;
}
