.top_info_bar {
  height: 5rem;
  background-color: orange;
  padding: 0 20px;

  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .top_info_bar h3 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 950px) {
  .top_info_bar h3 {
    font-size: 1.3vw;
  }
}

.top_info_bar a {
  color: rgb(28, 101, 184);
}
