.info_bar {
  height: 5rem;
  background-color: white;
  /* background-color: #2e2e2e; */
  color: #21bbef;
  border: 1px solid #e0e0e0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dark .info_bar {
  background-color: #2e2e2e;
  border: 1px solid #3e3e3e;
}

.info_bar.warning {
  color: #ff9f17;
}

.info_bar.error {
  color: #e7275e;
}

.info_bar.success {
  color: #62b22e;
}

.info_bar .info_message {
  font-size: 1.4rem;
  font-weight: 500;
  margin-left: 2rem;
}

.info_bar .loading_icon {
  height: 3rem;
  width: 3rem;
  margin-right: 2rem;
  background-image: url(../../assets/gifs/compile.gif);
  background-repeat: no-repeat;
  background-position: center center;
}

.info_bar .connect_icon {
  height: 4rem;
  width: 4rem;
  margin-right: 2rem;
  border: 0;
  background-image: url(../../assets/icons/icon_connect.svg);
  background-color: #21bbef;
  background-repeat: no-repeat;
  background-position: center center;
  outline: none;
}

.info_bar .connect_icon:hover {
  background-color: #1b99c3;
}

.info_bar .upload_icon {
  height: 4rem;
  width: 4rem;
  margin-right: 2rem;
  border: 0;
  background-image: url(../../assets/icons/icon_play.svg);
  background-color: #62b22e;
  background-repeat: no-repeat;
  background-position: center center;
  outline: none;
}

.info_bar .upload_icon:hover {
  background-color: #539727;
}

.info_bar .clickable {
  cursor: pointer;
}

.info_bar .clickable:active {
  position: relative;
  top: 1px;
}
