div.terminal-wrapper {
  flex: 0 1 50px;
  overflow: hidden;
}

div.terminal-wrapper.light {
  border-top: 1px solid #dedede;
}

div.terminal-wrapper.dark {
  border-top: 1px solid black;
}

div.terminal-wrapper.open {
  flex: 1 1 50px;
}

div.terminal-header {
  height: 50px;
  font-size: 1.5rem;
  font-weight: 500;
  color: white;
  background: black;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.terminal-header:hover {
  background: #111111;
}

.terminal_header_left,
.terminal_header_right {
  margin: 0px 2rem;

  display: flex;
  align-items: center;
}

.terminal_header_right {
  cursor: pointer;
  border: none;
  outline: none;
  touch-action: manipulation;
  color: white;
  background-color: black;
}

.terminal_header_left p {
  font-size: 1.3rem;
  text-transform: uppercase;
}

.terminal_header_left .output_icon {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  border: none;
  outline: none;
  touch-action: manipulation;
  display: inline-block;
  background-color: black;
  margin-left: 1rem;
  background-image: url(../../assets/icons/icon_terminal.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px;
  border: 1px solid #3e3e3e;
}

.terminal_header_left .output_icon.polling {
  background-image: url(../../assets/gifs/terminal.gif);
  background-color: #e7275e;
  border: 1px solid #e7275e;
}

.terminal_header_left .output_icon:hover {
  background-color: #2e2e2e;
}

.terminal_header_right:hover {
  color: #00a7e3;
}

.terminal_header_right .bin_icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  background-image: url(../../assets/icons/icon_bin.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.terminal_header_right:hover .bin_icon {
  background-image: url(../../assets/icons/icon_bin_selected.svg);
}
