.simulator_wrapper {
  font-size: 1.4rem;
  padding: 1.5rem;
}

.simulator_wrapper .card {
  width: 96%;
  /* height: 50rem; */
  margin: 0px auto;
  position: relative;
}

.simulator_wrapper .card .unknown_function {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  text-align: center;
  font-size: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
}
