.settings {
  font-size: 1.6rem;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

label {
  padding: 10px 0;
}

select {
  padding: 0.5rem;
  border: none;
  outline: none;
  background-color: #dedede;
  margin: 10px 0;
}
option {
  border: none;
  outline: none;
  background-color: #dedede;
}
option:hover {
  background-color: #00a7e3;
}

.fontSizeSelector {
  width: 20%;
  padding: 0.5rem;
  border: none;
  outline: none;
  background-color: #dedede;
  margin: 10px 0;
}
