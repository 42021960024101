div.examples {
  padding: 12px 12px;
}

div.example-group {
  margin-bottom: 24px;
}

div.examples ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

div.examples li.example {
  font-size: 1.6rem;
  padding: 1rem 0;
  color: #00a7e3;
  cursor: pointer;
  user-select: none;
}

div.examples li.example_category {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1rem 0;
  margin: 1rem 0 0.5rem 0;
  user-select: none;
  border-bottom: 2px solid;
}
