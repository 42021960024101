.sub_sub_category_wrapper {
  flex: 0 1 5rem;
  justify-content: flex-start;
  overflow: hidden;
  margin-left: 1rem;
  border-bottom: 1px solid #e0e0e0;

  display: flex;
  flex-direction: column;
}

.sub_sub_category_wrapper.dark {
  border-bottom: 1px solid #3e3e3e;
}

.sub_sub_category_wrapper.open {
  flex: 1 0 auto;
}

.sub_sub_category_header {
  min-height: 5rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sub_sub_category_header .sub_sub_category_header_icon {
  width: 13px;
  height: 13px;
  margin: 1rem;
  background-image: url(../../assets/icons/icon_arrow_big.svg);
  background-repeat: no-repeat;
  transition: transform 0.2s ease-in-out;
}

.sub_sub_category_header.dark .sub_sub_category_header_icon {
  background-image: url(../../assets/icons/icon_arrow_big_white.svg);
}

.sub_sub_category_header.open .sub_sub_category_header_icon {
  transform: rotate(90deg);
}

.sub_sub_category_header_name {
  font-size: 1.4rem;
  font-weight: 500;
  user-select: none;
}

.sub_sub_category_header_name span.matches {
  background-color: #ff9f17;
}

.sub_sub_category_header.open .sub_sub_category_header_name {
  font-weight: 800;
}

.sub_sub_category_header_label {
  margin-left: 2rem;
  background-color: #ffde17;
  color: black;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 0.75rem 0.9rem 0.6rem 0.75rem;
  text-align: start;
}

.sub_sub_category_body {
  flex: 1;
  visibility: hidden;
  margin: -1rem 1rem 0.5rem 1rem;

  display: block;
  overflow: auto;
}

.sub_sub_category_wrapper.open .sub_sub_category_body {
  visibility: visible;
}
