div.my-oxocard {
  font-size: 1.6rem;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

div.my-oxocard h2 {
  padding-bottom: 2rem;
}

div.my-oxocard button {
  margin: 15px 5px 0px 0px;
}

div.property {
  color: gray;
  margin: 0.5rem 0px;
  font-weight: 500;
}
