.graph_wrapper {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(47, 46, 58, 0.4);
}

.graph_content {
  min-height: 40rem;
  min-width: 60rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}
