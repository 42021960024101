.data_list_wrapper {
  max-height: 40rem;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.data_list_wrapper.light {
  color: #fff;
}

.data_list_header {
  min-height: 4rem;
  background-color: black;
  letter-spacing: 0.5px;

  display: flex;
  align-items: center;
}

.data_list_header .label {
  padding-left: 1rem;
  flex: 3;
}

.data_list_header .controls {
  flex: 2;
  margin: 0 1rem;

  display: flex;
  justify-content: flex-end;
}

.data_list_body {
  overflow: auto;

  display: flex;
  flex-direction: column;

  max-height: 12rem;
}

.data_list_item_wrapper {
  display: inline;
}

.data_list_item {
  display: flex;
}

.data_list_item .timestamp {
  background-color: #414141;
  color: #ffffff;
  padding: 0.5rem 1rem 0 1rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.data_list_item_wrapper:last-of-type .timestamp {
  padding-bottom: 1rem;
}

.data_list_item .value {
  background-color: #2e2e2e;
  padding-left: 1rem;
  padding-top: 0.5rem;
  flex: 1;
}

.controls .icon {
  width: 1.7rem;
  height: 1.7rem;
  margin-left: 1rem;
}

.controls .icon.clear {
  background-image: url(../../../assets/icons/icon_clear.svg);
  background-repeat: no-repeat;
}

.controls .icon.clear:hover {
  background-image: url(../../../assets/icons/icon_clear_selected.svg);
}

.controls .icon.download {
  background-image: url(../../../assets/icons/icon_download.svg);
  background-repeat: no-repeat;
}

.controls .icon.download:hover {
  background-image: url(../../../assets/icons/icon_download_selected.svg);
}

.controls .icon.graph {
  background-image: url(../../../assets/icons/icon_graph.svg);
  background-repeat: no-repeat;
}

.controls .icon.graph:hover {
  background-image: url(../../../assets/icons/icon_graph_selected.svg);
}

.controls .icon:active {
  position: relative;
  top: 1px;
}

.data_hash {
  font-size: 1.4rem;
  padding: 1.5rem 0;
}

.data_hash a {
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  margin-left: 1rem;
}

.data_hash a:link {
  color: #21bbef;
}

.data_hash a:visited {
  color: #62b22e;
}

.data_hash a:hover {
  color: #ff9f17;
}

.data_hash a:active {
  color: #ff9f17;
}
