.editor-error {
  color: aliceblue;
  width: 100%;
  padding: 10px;
  background-color: crimson;
}

.myInlineDecoration {
  background: rgba(220, 20, 60, 0.25);
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  font-style: oblique;
}

.myLineDecoration {
  background: crimson;
  width: 20px !important;
  border-radius: 25px;
  margin-left: 3px;
}
