.constant_description {
  user-select: none;
  white-space: pre-wrap;
}

.constant_wrapper {
  margin-right: 1rem;
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.constant_wrapper .constant {
  margin: 0.2rem 0;

  display: flex;
  align-items: center;
}

.constant_wrapper .constant .name {
  font-weight: 500;
  margin-right: 0.1rem;
  color: #9d00b5;
}

.constant_example_wrapper {
  margin: 2rem 0;
}

.constant_example_title {
  margin-top: 1rem;
  font-weight: 600;
}

.constant_example {
  margin: 2rem 0;
}

.constant_example p {
  padding: 0.5rem;
  background-color: rgb(236, 236, 236);
  font-weight: 600;
  color: black;
  white-space: pre-wrap;
  font-family: Consolas, 'Courier New', monospace;
  tab-size: 4;
}

.constant_example button.copy_example {
  text-decoration: none;
  background-color: #3e3e3e;
  color: white;
  cursor: pointer;
  border: none;
  padding: 0.5rem 1rem;
}

.constant_example button.copy_example:hover {
  background-color: #62b22e;
}
.constant_example button.copy_example:active {
  position: relative;
  top: 1px;
}
