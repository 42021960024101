.feedback_wrapper {
  font-size: 1.4rem;
  padding: 1.5rem;
}

.feedback_wrapper a {
  font-size: 1.6rem;
  font-weight: 500;
}

.feedback_wrapper a:link {
  color: #21bbef;
}

.feedback_wrapper a:visited {
  color: #62b22e;
}

.feedback_wrapper a:hover {
  color: #ff9f17;
}

.feedback_wrapper a:active {
  color: #ff9f17;
}

.impressum {
	margin-top: 4rem;
}