.sub_category_wrapper {
  flex: 0 1 5rem;
  justify-content: flex-start;
  overflow: hidden;

  border-bottom: 1px solid #e0e0e0;

  display: flex;
  flex-direction: column;
}

.sub_category_wrapper.dark {
  border-bottom: 1px solid #3e3e3e;
}

.sub_category_wrapper.open {
  border-bottom: none;
}

.sub_category_wrapper.open {
  flex: 1 0 auto;
}

.sub_category_header {
  min-height: 5rem;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sub_category_header.open {
  background-color: #e0e0e0;
}

.sub_category_header.dark.open {
  background-color: #3e3e3e;
}

.sub_category_header .sub_category_header_icon {
  width: 13px;
  height: 13px;
  margin: 1rem;
  background-image: url(../../assets/icons/icon_plus_big.svg);
  background-repeat: no-repeat;
  transition: background-image 0.2s ease-in-out;
}

.sub_category_header.dark .sub_category_header_icon {
  background-image: url(../../assets/icons/icon_plus_big_white.svg);
}

.sub_category_header.open .sub_category_header_icon {
  width: 13px;
  height: 13px;
  margin: 1rem;
  background-image: url(../../assets/icons/icon_minus_big.svg);
  background-repeat: no-repeat;
}

.sub_category_header.dark.open .sub_category_header_icon {
  background-image: url(../../assets/icons/icon_minus_big_white.svg);
}

.sub_category_header_name {
  font-size: 1.4rem;
  font-weight: 500;
  user-select: none;
}
.sub_category_header_latest {
  margin-left: 1rem;
}

.sub_category_header_label {
  margin-right: 1rem;
  margin-left: auto;
  background-color: #ffde17;
  color: black;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 0.75rem 0.9rem 0.6rem 0.75rem;
  text-align: start;
}

.sub_category_body {
  flex: 1;

  display: block;
  overflow: auto;
}

.sub_category_body.light {
  background-color: #f3f3f3;
}

.sub_category_body.dark {
  background-color: #2e2e2e;
}
