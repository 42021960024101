.menu_category_wrapper {
  flex: 0 1 5rem;
  justify-content: flex-start;
  overflow: hidden;
  transition: flex-grow 0.1s ease-in-out;
  margin: 0 2rem;
  border-top: 1px solid #e0e0e0;

  display: flex;
  flex-direction: column;
}

.menu_category_wrapper.dark {
  border-top: 1px solid #3e3e3e;
}

.menu_category_wrapper:first-of-type {
  margin-top: 5rem;
}

.menu_category_wrapper.light:last-of-type {
  border-bottom: 1px solid #e0e0e0;
}

.menu_category_wrapper.dark:last-of-type {
  border-bottom: 1px solid #3e3e3e;
}

.menu_category_wrapper.open {
  flex: 1 0 5rem;
}

@media only screen and (max-height: 800px) {
  .menu_category_wrapper {
    max-height: 5rem;
  }

  .menu_category_wrapper.open {
    transition: max-height 0.5s ease-in-out;
    max-height: 500rem;
  }
}

.menu_category_header {
  min-height: 5rem;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu_category_header.open {
  background-color: #e0e0e0;
}

.menu_category_header.dark.open {
  background-color: #3e3e3e;
}

.menu_category_header .menu_category_header_icon {
  width: 1.4rem;
  height: 1.4rem;
  margin: 1rem;
  background-image: url(../../assets/icons/icon_plus_big.svg);
  background-repeat: no-repeat;
  transition: background-image 0.2s ease-in-out;
}

.menu_category_header.dark .menu_category_header_icon {
  background-image: url(../../assets/icons/icon_plus_big_white.svg);
}

.menu_category_header.open .menu_category_header_icon {
  width: 1.4rem;
  height: 1.4rem;
  margin: 1rem;
  background-image: url(../../assets/icons/icon_minus_big.svg);
  background-repeat: no-repeat;
}

.menu_category_header.dark.open .menu_category_header_icon {
  background-image: url(../../assets/icons/icon_minus_big_white.svg);
}

.menu_category_header_name {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: 1rem;
  user-select: none;
}

.menu_category_header_name .new {
  color: #79b51c;
  margin-left: 1rem;
}

.menu_category_header.dark .menu_category_header_name {
  font-weight: 400;
}

.menu_category_body {
  flex: 1;
  margin: 1rem 0;

  display: block;
  overflow: auto;
}
