.worksheets_wrapper {
  font-size: 1.4rem;
  padding: 1.5rem;
}

.worksheets_wrapper a {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
}

.worksheets_wrapper a:link {
  color: #21bbef;
}

.worksheets_wrapper a:visited {
  color: #62b22e;
}

.worksheets_wrapper a:hover {
  color: #ff9f17;
}

.worksheets_wrapper a:active {
  color: #ff9f17;
}
