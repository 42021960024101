.app {
  height: calc(100% - 5rem);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

div.main_area {
  flex: 3;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

div.menu_area {
  background-color: #dedede;
  flex: 1;
  min-width: 40rem;
  display: block;
  padding: 1rem;
}

@media only screen and (max-width: 663px) {
  div.menu_area {
    visibility: hidden;
    flex: 0;
    min-width: 0;
    padding: 0;
  }
}

.app.dark div.menu_area {
  background-color: #2e2e2e;
}
