.top_bar {
  height: 6rem;

  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1157px) {
  .top_bar {
    height: 10rem;
  }
}

.top_bar.light {
  background-color: white;
}

.top_bar.dark {
  background-color: #2e2e2e;
}

.top_bar_left {
  margin: 0px 2rem;
  flex: 5;

  display: flex;
  align-items: center;
}

.top_bar_right {
  flex: 3;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.top_bar_logo {
  height: 70%;
  width: 6rem;
  margin-right: 2rem;
  background-image: url(../../assets/logos/logo_oxoscript_blink.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.top_bar_left form {
  width: 100%;
  min-width: 20rem;
}

.top_bar.dark #top_bar_input {
  background-color: #2e2e2e;
  color: white;
}

#top_bar_input {
  border: none;
  font-size: 1.8rem;
  font-weight: bold;
  outline: none;
  width: 100%;
}

.top_bar_btn {
  background-color: #f1f1f1;
  height: 65%;
  flex: 1;
  margin-left: 1rem;
  padding: 0 2rem;
  cursor: pointer;
  border: 0;

  display: flex;
  align-items: center;
  outline: none;
}

.top_bar_btn:hover {
  background-color: #bebebe;
}

.top_bar_btn:active {
  position: relative;
  top: 1px;
}

.top_bar_btn:last-of-type {
  margin-right: 1rem;
}

.top_bar_btn .icon {
  height: 1.4rem;
  width: 1.4rem;
  margin-right: 1rem;
}

.top_bar_btn p {
  font-size: 1.4rem;
}

.top_bar_btn .icon.new {
  background-image: url(../../assets/icons/icon_new.svg);
}

.top_bar_btn .icon.open {
  background-image: url(../../assets/icons/icon_open.svg);
}

.top_bar_btn .icon.save {
  background-image: url(../../assets/icons/icon_save.svg);
}

.top_bar_btn .icon.share {
  background-image: url(../../assets/icons/icon_share.svg);
}

a.share_link {
  color: #00a7e3;
  font-weight: 600;
  text-decoration: none;
}
