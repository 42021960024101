.syntax_element_wrapper {
  margin-right: 1rem;
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.syntax_element_description {
  user-select: none;
  white-space: pre-wrap;
}

.syntax_example {
  margin-top: 2rem;
  width: 100%;
}

.syntax_example_name {
  user-select: none;
  font-weight: 600;
  color: #62b22e;
  margin-bottom: 0.5rem;
}

.syntax_example_description {
  user-select: none;
  margin-bottom: 0.5rem;
}

.syntax_example_description pre {
  white-space: pre-wrap;
}

.syntax_example_code_wrapper {
  overflow: auto;
  background-color: rgb(236, 236, 236);
}

.syntax_example_code {
  margin: 1rem;
  display: inline-block;
}

.syntax_example_code pre {
  font-weight: 600;
  color: black;
  font-family: Consolas, 'Courier New', monospace;
  tab-size: 4;
  margin-top: 0.5rem 0.5rem 0 0;
}

span.keyword {
  /* def, main, if, else, while, ... */
  color: #00a7e3;
}

span.function {
  /* setPixel() */
  color: #5a9400;
}

span.custom_function {
  /* def myFunction() */
  color: #e67300;
}

span.constant {
  /* WIFI */
  color: #9d00b5;
}

span.number {
  /* 123 */
  color: #09885a;
}

span.comment {
  /* # myComment */
  color: #008000;
}

span.string {
  /* # "myString" */
  color: #a31515;
}
